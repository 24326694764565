import { useEffect } from 'react'
import { useModuleFromWindow } from './useModuleFromWindow'

type IntercomMethods = 'update' | 'onShow' | 'show' | 'onHide' | 'hide'
type Intercom = (method: IntercomMethods, data?: Record<string, unknown> | (() => void)) => void

export const useIntercom = ({ hideLauncher = false }: { hideLauncher?: boolean } = {}) => {
  const { module: intercom } = useModuleFromWindow<Intercom>('Intercom')

  useEffect(() => {
    if (intercom) {
      intercom('update', {
        hide_default_launcher: hideLauncher
      })
    }
  }, [intercom, hideLauncher])

  return {
    show: () => {
      if (intercom) {
        intercom('show')
      }
    },
    onShow: (callback: () => void) => {
      if (intercom) {
        intercom('onShow', callback)
      }
    },
    hide: () => {
      if (intercom) {
        intercom('update', {
          hide_default_launcher: true
        })
      }
    },
    onHide: (callback: () => void) => {
      if (intercom) {
        intercom('onHide', callback)
      }
    },
    intercom
  }
}
