import { SearchIndex } from '@hermes/api-sdk'
import { useLocale } from '@hooks/useLocale'
import { useUser } from '@lib/contexts/userContext'
import { KonvoloParams } from '@lib/utils/konvoloHelpers'
import { useRouter } from 'next-nprogress-bar'

type NavigationPaths = {
  homePage: []
  authPage: [redirectPath?: string]
  productPage: [bookSlug: string]
  productSellersPage: [bookSlug: string, deliveryMethod?: string]
  moreFromSellerPage: [sellerUuid: string]
  checkoutPage: [sellerUuid: string]
  checkoutConfirmationPage: [deliveryMethod: 'meetup' | 'shipping', transactionSessionId: string | null]
  searchPage: [query?: string, genreCode?: string, index?: SearchIndex]
  categoriesPage: []
  explorePage: []
  feedBackPage: [dataCid?: string, originPage?: string]
}

export const navigationPaths: { [K in keyof NavigationPaths]: (...args: NavigationPaths[K]) => string } = {
  homePage: () => '',
  moreFromSellerPage: (sellerUuid) => `/request/${sellerUuid}/more-from-seller`,
  authPage: (redirectPath) => {
    if (redirectPath) return `/auth?successPath=${redirectPath}`
    return '/auth'
  },
  productSellersPage: (bookSlug, deliveryMethod = 'shipping') =>
    `/books/${bookSlug}/sellers?deliveryMethod=${deliveryMethod}`,
  productPage: (bookSlug) => `/books/${bookSlug}`,
  checkoutPage: (sellerUuid) => `/request/${sellerUuid}/checkout`,
  checkoutConfirmationPage: (deliveryMethod = 'shipping', transactionSessionId = null) =>
    `/request/confirmation?deliveryMethod=${deliveryMethod}&transactionSessionId=${transactionSessionId}`,
  searchPage: (query, genreCode, index = SearchIndex.NORWAY) => {
    const params = new URLSearchParams()
    if (query) params.set('query', query)
    if (genreCode) params.set(`${index}[refinementList]`, `genres.code:${genreCode}`)
    return `/search${params.toString() ? `?${params.toString()}` : ''}`
  },
  categoriesPage: () => '/categories',
  explorePage: () => '/explore',
  feedBackPage: (dataCid, originPage) => {
    const params = new URLSearchParams()
    if (dataCid) params.set(KonvoloParams.dataCid, dataCid)
    if (originPage) params.set(KonvoloParams.originPage, originPage)
    return `/feedback${params.toString() ? `?${params.toString()}` : ''}`
  }
}

const authenticatedPaths: Partial<Record<keyof NavigationPaths, boolean>> = {
  checkoutPage: true
}

const disableProgressBarPaths: Partial<Record<keyof NavigationPaths, boolean>> = {
  searchPage: true
}

export const useNavigation = () => {
  const { user } = useUser()
  const router = useRouter()
  const locale = useLocale()
  const createLocalizedPath = (path: string) => `/${locale}${path}`

  const navigate = Object.keys(navigationPaths).reduce(
    (acc, key) => {
      const typedKey = key as keyof NavigationPaths
      const isAuthRequired = authenticatedPaths[typedKey] ?? false
      const isDisabledProgressBarPath = disableProgressBarPaths[typedKey] ?? false
      acc[typedKey] = (...args: any[]) => {
        const pathFunction: (...args: any[]) => string = navigationPaths[typedKey]

        const path = createLocalizedPath(pathFunction(...args))
        const authSuccessPathQuery = encodeURIComponent(path)
        const authPath = createLocalizedPath(navigationPaths.authPage(authSuccessPathQuery))
        if (path && isAuthRequired && !user) {
          router.push(authPath)
          return acc
        }
        if (path && isDisabledProgressBarPath) {
          router.push(path, {}, { showProgressBar: false })
          return acc
        }
        if (path) {
          router.push(path)
        }
      }
      return acc
    },
    {} as { [K in keyof NavigationPaths]: (...args: NavigationPaths[K]) => void }
  )

  return { navigate }
}
