import { SortByAttribute, SortingOption } from '@hermes/api-sdk'
import { useInternalBookSearchSortAndFilter } from './useInternalBookSearchSortAndFilter'

export const getSortingOptions = (): SortingOption[] =>
  Object.values(SortByAttribute).map((value) => ({
    value
  }))

export const useBookSearchSortBy = () => {
  const { setCurrentSortOption, currentSortOption } = useInternalBookSearchSortAndFilter()
  const sortingOptions = getSortingOptions()
  const defaultOption = sortingOptions[0]

  const setSorting = (arg: SortingOption) => {
    const selectedOption = sortingOptions.find((option) => option.value === arg.value)
    if (selectedOption) {
      setCurrentSortOption(selectedOption)
    }
  }

  const resetSorting = () => {
    setCurrentSortOption(defaultOption)
  }

  return {
    setSorting,
    resetSorting,
    defaultOption,
    currentSortOption,
    sortingOptions
  }
}
