'use client'

import { useModuleFromWindow } from '@hooks/useModuleFromWindow'
import { getPublicConfigVariable } from '@lib/utils/getConfigVariable'
import { GoogleTagManager, sendGTMEvent } from '@next/third-parties/google'
import moment from 'moment'
import posthog from 'posthog-js'
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { useUser } from './userContext'
import { useIntercom } from '@hooks/useIntercom'

const POSTHOG_API_KEY = getPublicConfigVariable('POSTHOG_API_KEY')
const POSTHOG_HOST = getPublicConfigVariable('POSTHOG_HOST')

const eventToGTMEvent = (event: string) => {
  if (event === 'ORDER_SUBMITTED') {
    return 'purchase'
  }
  return event.toLowerCase()
}

const useAnalyticsInternal = () => {
  const trackEvent = useCallback(<T extends EventType>(event: T, data: EventData<T>) => {
    try {
      posthog.capture(event, data)
      sendGTMEvent({ event: eventToGTMEvent(event), data })
    } catch (error) {
      console.error('Error tracking event:', error)
    }
  }, [])

  return { trackEvent }
}

const AnalyticsContext = createContext<ReturnType<typeof useAnalyticsInternal> | null>(null)

export const AnalyticsProvider = ({ children }: { children: React.ReactNode }) => {
  const [initialized, setInitialized] = useState({
    gtm: false,
    intercom: false,
    posthog: false,
    posthogIdentification: false
  })
  const { user: userInfo } = useUser()
  const analyticsEnabled = Boolean(process.env.NEXT_PUBLIC_ENABLE_ANALYTICS) ?? false
  const analytics = useAnalyticsInternal()
  const { onShow: onIntercomShow, onHide: onIntercomHide, intercom: Intercom } = useIntercom({ hideLauncher: false })
  const { module: CookieInformation } = useModuleFromWindow<typeof window.CookieInformation>('CookieInformation')

  const consentStatus = CookieInformation?.getStatusOfUsedConsentTypes() || {}

  const initializeDataLayerGTM = useCallback(() => {
    if (userInfo && analyticsEnabled && !initialized.gtm) {
      const userData = {
        event: 'userData',
        distinctId: userInfo.id,
        userUuid: userInfo.guid,
        userName: `${userInfo.firstName} ${userInfo.lastName}`,
        userEmail: userInfo.email,
        userCreated: moment(userInfo.createdAt).unix(),
        intercomUserHash: userInfo.intercomUserHash,
        intercomAppId: getPublicConfigVariable('INTERCOM_APP_ID')
      }
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push(userData)
      setInitialized((prev) => ({ ...prev, gtm: true }))
    }
  }, [userInfo, initialized.gtm])

  const initializeIntercomEventSubscription = () => {
    if (analyticsEnabled && !initialized.intercom) {
      onIntercomShow(() => {
        analytics.trackEvent('HELP_CENTER_OPENED', {})
      })
      onIntercomHide(() => {
        analytics.trackEvent('HELP_CENTER_CLOSED', {})
      })
      setInitialized((prev) => ({ ...prev, intercom: true }))
    }
  }

  const initializePosthog = useCallback(() => {
    posthog.init(POSTHOG_API_KEY, { api_host: POSTHOG_HOST, capture_pageview: false, autocapture: false })
    setInitialized((prev) => ({ ...prev, posthog: true }))
  }, [initialized.posthog])

  const initializePosthogIdentification = useCallback(() => {
    const hasPermission = !!consentStatus?.cookie_cat_statistic
    if (userInfo && analyticsEnabled && hasPermission && !initialized.posthogIdentification) {
      posthog.identify(userInfo.id.toString(), { email: userInfo.email })
      setInitialized((prev) => ({ ...prev, posthogIdentification: true }))
    }
  }, [userInfo, consentStatus, initialized.posthogIdentification])

  const initializeCookieConsentEventListeners = useCallback(() => {
    if (CookieInformation && analyticsEnabled) {
      window.addEventListener('CookieInformationConsentSubmitted', handleConsentSubmitted)
      return () => {
        window.removeEventListener('CookieInformationConsentSubmitted', handleConsentSubmitted)
      }
    }
  }, [analyticsEnabled, initialized.posthog])

  const handleConsentSubmitted = useCallback(() => {
    analytics.trackEvent('COOKIE_CONSENT_SUBMITTED', { consentStatus })
    if (consentStatus?.cookie_cat_statistic) {
      posthog.opt_in_capturing()
    } else {
      posthog.opt_out_capturing()
    }
  }, [consentStatus])

  useEffect(() => {
    initializePosthog()
  }, [])

  useEffect(() => {
    initializeCookieConsentEventListeners()
  }, [CookieInformation])

  useEffect(() => {
    initializeIntercomEventSubscription()
  }, [Intercom])

  useEffect(() => {
    initializeDataLayerGTM()
    initializePosthogIdentification()
  }, [userInfo, consentStatus])

  return (
    <AnalyticsContext.Provider value={analytics}>
      <GoogleTagManager
        gtmId={process.env.NEXT_PUBLIC_GTM_ID || ''}
        preview={process.env.NEXT_PUBLIC_GTM_ENV ?? undefined}
        auth={process.env.NEXT_PUBLIC_GTM_AUTH ?? undefined}
      />
      {children}
    </AnalyticsContext.Provider>
  )
}

export const useAnalytics = () => {
  const context = useContext(AnalyticsContext)
  if (!context) {
    throw new Error('useAnalytics must be used within an AnalyticsProvider')
  }
  return context
}

type EventPayloads = {
  COOKIE_CONSENT_SUBMITTED: { consentStatus: Record<string, boolean> }
  ORDER_SUBMITTED: { paymentSessionId: string }
  PAGE_VIEWED: { pageName: string; pageRoute: string }
  ELEMENT_CLICKED: {}
  PRODUCT_VIEWED: { productId: number }
  PRODUCT_CLICKED: { productId: number; page: string }
  PRODUCT_SCANNED: { productId: number }
  PRODUCT_CREATED: { productId: number }
  PRODUCT_ADDED_TO_CART: { productId: number }
  PRODUCT_REMOVED_FROM_CART: { productId: number }
  SEARCH_STARTED: {}
  SEARCH_ENDED: { searchQuery: string }
  SEARCH_QUERY_UPDATED: { searchQuery: string }
  SEARCH_REFINEMENT_ADDED: { refinement: string; refinementValue: string }
  SEARCH_REFINEMENT_REMOVED: { refinement: string }
  SEARCH_SORTING_UPDATED: { sort: string }
  SEARCH_SEMANTIC_TOGGLED: {}
  HELP_CENTER_OPENED: {}
  HELP_CENTER_CLOSED: {}
  HELP_CENTER_CONVERSATION_STARTED: {}
  APPLICATION_ERROR: { name: string; message: string }
  ORDER_SUBMITTED_GIFTCARD: { value: number; currency: string; orderId: string }
}

type EventType = keyof EventPayloads
type EventData<T extends EventType> = EventPayloads[T]
