import React from 'react'
import { useTheme } from '../../styles/useTheme'
import { Box } from '../Box'
import { Typography } from '../Typography'

import { ImageContainer, BookCardContainer, BookCardInfo, ChipPrice } from './styles'

interface BookCardProps {
  title: string
  cardAction?: () => void
  chipAction?: () => void
  globalAction?: () => void
  chipActive?: boolean
  image: React.ReactNode
  cover?: string
  year?: string
  chipLabel?: string
  inline?: boolean
  author?: string
  hover?: boolean
  textHover?: boolean
  additionalInfo?: React.ReactNode
  conditionInfo?: React.ReactNode
  versionInfoStyle?: boolean
  chipDisabled?: boolean
}

function BookCard({
  title,
  cardAction = () => null,
  chipAction = () => null,
  globalAction = () => null,
  chipActive = false,
  image,
  cover,
  year,
  chipLabel,
  textHover = false,
  chipDisabled = false,
  author,
  inline = false,
  hover = false,
  additionalInfo,
  conditionInfo,
  versionInfoStyle = false
}: BookCardProps) {
  const theme = useTheme()
  return (
    <Box
      className="book-card"
      sx={{
        ...(hover && {
          padding: '12px 0',
          '&:hover': {
            backgroundColor: theme.palette.neutral[100]
          }
        })
      }}
      onClick={globalAction}
    >
      <BookCardContainer hover={textHover} inline={inline}>
        <Box onClick={cardAction}>
          <Box display="flex" flex="1">
            <ImageContainer className="book-card-image" inline={inline}>
              {image}
            </ImageContainer>
          </Box>
          <BookCardInfo versionInfoStyle={versionInfoStyle} inline={inline}>
            {conditionInfo && conditionInfo}
            <Typography className="book-card-title">{title}</Typography>
            <Typography className="author-title">
              {(() => {
                const authorText = author ? `${author}` : ''
                const coverText = cover ? `${cover}` : ''
                const yearText = year ? `${year}` : ''

                const separator = inline ? ' · ' : ' • '
                const authorAndCover =
                  authorText && coverText ? `${authorText}${separator}${coverText}` : authorText || coverText

                return authorAndCover + (yearText ? `${inline ? ' · ' : ' • '}${yearText}` : '')
              })()}
            </Typography>
            {additionalInfo && additionalInfo}
          </BookCardInfo>
        </Box>
        {chipLabel && (
          <ChipPrice
            chipActive={chipActive}
            onClick={chipAction}
            variant="outlined"
            label={chipLabel}
            color={inline ? 'default' : 'primary'}
            inline={inline}
            disabled={chipDisabled}
          />
        )}
      </BookCardContainer>
    </Box>
  )
}

export { BookCard, type BookCardProps }
