import { MeiliSearchResponse } from '@hermes/api-sdk'
import { useContext } from 'react'
import { SearchContext } from './searchContext'

export const useBookSearch = (): {
  hits: MeiliSearchResponse['hits']
  isLoading: boolean
  nextPage: () => void
  hasMore: boolean
  query: string
  pagination: {
    totalHits: number
  }
  setQuery: (query: string, semanticEnabled?: boolean) => void
  resetQuery: () => void
  perPage: number
  setPerPage: React.Dispatch<React.SetStateAction<number>>
  semanticEnabled: boolean
  setSemanticEnabled: React.Dispatch<React.SetStateAction<boolean>>
} => {
  const context = useContext(SearchContext)

  if (!context) {
    throw new Error('useBookSearch must be used within a SearchProvider')
  }

  return {
    hits: context.hits,
    isLoading: context.isLoading,
    nextPage: context.nextPage,
    hasMore: context.hasMore,
    pagination: context.pagination,
    query: context.query,
    setQuery: (query: string, semanticEnabled = context.semanticEnabled) => {
      context.setQuery(query)
      if (semanticEnabled !== context.semanticEnabled) {
        context.setSemanticEnabled(semanticEnabled)
      }
    },
    resetQuery: context.resetQuery,
    perPage: context.perPage,
    setPerPage: context.setPerPage,
    semanticEnabled: context.semanticEnabled,
    setSemanticEnabled: context.setSemanticEnabled
  }
}
