// This is the cid found on the Konvolo platform
export enum KonvoloChat {
  GENERAL_FEEDBACK_WEB = '67c9870764c8f6001003608c',
  REPORT_ERROR_WEB = '67c9872564c8f600100360ec'
}

export enum KonvoloParams {
  dataCid = 'dataCid',
  originPage = 'originPage'
}

export const isValidKonvoloChat = (value: string | null): value is KonvoloChat => {
  return value !== null && Object.values(KonvoloChat).includes(value as KonvoloChat)
}
