import { useBookSearchInfinite } from '@hermes/api-sdk'
import { createContext } from 'react'

import { useInternalSearchProviderState } from './useInternalSearchProviderState'

type SearchStateResponse = ReturnType<typeof useInternalSearchProviderState>

type SearchResultsResponse = ReturnType<typeof useBookSearchInfinite>

export const SearchContext = createContext<(SearchStateResponse & SearchResultsResponse) | null>(null)
