import { FacetAttribute, FacetListItem, Refinement } from '@hermes/api-sdk'
import { useEffect, useRef } from 'react'
import { useInternalBookSearchSortAndFilter } from './useInternalBookSearchSortAndFilter'

export const useBookSearchRefinements = () => {
  const { activeRefinements, setActiveRefinements, facetDistribution } = useInternalBookSearchSortAndFilter()

  const items = useRef<FacetListItem[]>([])

  const isValueRefined = (value: string, attri: string): boolean =>
    Array.from(activeRefinements).some((refinement) => refinement.attribute === attri && refinement.value === value)

  const setRefinement = (refinement: Refinement) => {
    if (refinement) {
      setActiveRefinements((prevRefinements) => {
        const newSet = new Set<Refinement>(prevRefinements)

        if (isValueRefined(refinement.value, refinement.attribute)) {
          newSet.forEach((item) => {
            if (item.value === refinement.value && item.attribute === refinement.attribute) {
              newSet.delete(item)
            }
          })
        } else {
          newSet.add({ value: refinement.value, attribute: refinement.attribute })
        }
        return newSet
      })
    }
  }

  const resetRefinements = () => {
    setActiveRefinements(new Set())
  }

  return {
    toggleRefinement: setRefinement,
    items: items.current,
    resetRefinements,
    activeRefinements,
    facetDistribution
  }
}

export const useBookSearchFacet = ({
  attribute,
  transformItems
}: {
  attribute: FacetAttribute
  transformItems?: (items: FacetListItem[]) => FacetListItem[]
}) => {
  const { activeRefinements, setActiveRefinements, facetDistribution, setFilterAttributes } =
    useInternalBookSearchSortAndFilter()

  const items = useRef<FacetListItem[]>([])

  const isValueRefined = (value: string, attri: FacetAttribute): boolean =>
    Array.from(activeRefinements).some((refinement) => refinement.attribute === attri && refinement.value === value)

  const setRefinement = (value: string) => {
    setActiveRefinements((prevRefinements) => {
      const newSet = new Set<Refinement>(prevRefinements)
      if (!isValueRefined(value, attribute)) {
        newSet.add({ value, attribute })
      }
      return newSet
    })
  }

  const toggleRefinement = (value: string) => {
    if (value) {
      setActiveRefinements((prevRefinements) => {
        const newSet = new Set<Refinement>(prevRefinements)

        if (isValueRefined(value, attribute)) {
          newSet.forEach((item) => {
            if (item.value === value && item.attribute === attribute) {
              newSet.delete(item)
            }
          })
        } else {
          newSet.add({ value, attribute })
        }
        return newSet
      })
    }
  }

  if (Object.keys(facetDistribution).length > 0) {
    const updatedItems = Object.entries(facetDistribution[attribute] || {}).map(([value, count]) => {
      const isRefined = isValueRefined(value, attribute)
      return {
        value,
        isRefined,
        count: Number(count),
        attribute,
        label: value
      }
    })
    const finalItems = transformItems ? transformItems(updatedItems) : updatedItems
    items.current = finalItems
  }

  useEffect(() => {
    if (attribute) {
      setFilterAttributes((prev) => Array.from(new Set([...prev, attribute])))
    }
  }, [])

  return {
    toggleRefinement,
    setRefinement,
    items: items.current,
    resetRefinements: () => setRefinement('')
  }
}
