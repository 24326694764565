import { SortingOption, useBookSearchInfinite, Refinement } from '@hermes/api-sdk'

import { useContext } from 'react'
import { SearchContext } from './searchContext'

type SearchResultsResponse = ReturnType<typeof useBookSearchInfinite>

export const useInternalBookSearchSortAndFilter = (): {
  activeRefinements: Set<Refinement>
  setActiveRefinements: React.Dispatch<React.SetStateAction<Set<Refinement>>>
  filterAttributes: string[]
  setFilterAttributes: React.Dispatch<React.SetStateAction<string[]>>
  currentSortOption: SortingOption
  setCurrentSortOption: (option: SortingOption) => void
  facetDistribution: SearchResultsResponse['facetDistribution']
} => {
  const context = useContext(SearchContext)
  if (!context) {
    throw new Error('useBookSearch must be used within a SearchProvider')
  }
  return {
    activeRefinements: context.activeRefinements,
    setActiveRefinements: context.setActiveRefinements,
    filterAttributes: context.filterAttributes || [],
    setFilterAttributes: context.setFilterAttributes,
    currentSortOption: context.currentSortOption,
    setCurrentSortOption: context.setCurrentSortOption,
    facetDistribution: context.facetDistribution
  }
}
