import { styledComponent } from '../../styles/styled'
import { Box } from '../Box'
import { Chip } from '../Chip'

export const ChipPrice = styledComponent(Chip, {
  shouldForwardProp: (prop) => prop !== 'inline' && prop !== 'chipActive'
})<{ inline: boolean; chipActive: boolean }>(({ inline, chipActive, theme }) => ({
  background: chipActive ? theme.palette.secondary.main : '',
  marginTop: '16px',
  fontWeight: 500,
  wordSpacing: 0,
  height: inline ? '24px' : 'auto',
  padding: inline ? '' : '8px 12px'
}))

export const ImageContainer = styledComponent(Box, {
  shouldForwardProp: (prop) => prop !== 'inline'
})<{ inline: boolean }>(({ inline, theme }) => ({
  width: inline ? '40px' : '206px',
  height: inline ? '56px' : '330px',
  cursor: 'pointer',
  position: 'relative',
  borderRadius: inline ? '4px' : '8px',
  [theme.breakpoints.down('md')]: {
    width: inline ? '40px' : '136px',
    height: inline ? '56px' : '224px'
  },
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: inline ? '4px' : '8px',
    position: 'absolute'
  }
}))

export const BookCardContainer = styledComponent(Box, {
  shouldForwardProp: (prop) => prop !== 'inline' && prop !== 'hover'
})<{ inline: boolean; hover: boolean }>(({ inline, hover, theme }) => ({
  display: inline ? 'flex' : 'block',
  flexDirection: 'row',
  justifyContent: 'space-between',
  cursor: inline ? 'pointer' : 'default',
  '.MuiBox-root:first-of-type': {
    display: 'flex',
    flexDirection: inline ? 'row' : 'column'
  },
  ...(hover && {
    '&:hover': {
      '.book-card-title': {
        color: theme.palette.primary.main
      }
    }
  })
}))

export const BookCardInfo = styledComponent(Box, {
  shouldForwardProp: (prop) => prop !== 'inline' && prop !== 'versionInfoStyle'
})<{ inline: boolean; versionInfoStyle: boolean }>(({ theme, inline, versionInfoStyle }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  ...(inline && {
    marginLeft: '16px',
    flexDirection: versionInfoStyle ? 'column-reverse' : 'column'
  }),
  p: {
    '&.book-card-title': {
      margin: inline ? '4px 0 0' : '8px 0 3px',
      WebkitLineClamp: '3',
      WebkitBoxOrient: 'vertical',
      display: '-webkit-box',
      cursor: 'pointer',
      overflow: 'hidden',
      ...(inline && !versionInfoStyle
        ? {
            fontWeight: 400,
            lineHeight: '20px',
            paddingBottom: '5px'
          }
        : {
            fontWeight: 500,
            lineHeight: '24px'
          }),
      ...(versionInfoStyle
        ? {
            fontSize: '12px',
            lineHeight: '16px',
            color: theme.palette.neutral.main
          }
        : {
            fontSize: '16px'
          })
    },
    '&.condition-info': {
      margin: inline ? '4px 0 0 0' : '8px 0 -4px',
      lineHeight: '16px',
      fontSize: '12px',
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
      color: theme.palette.neutral.main,
      fill: theme.palette.neutral.main
    },
    '&.author-title': {
      ...(versionInfoStyle
        ? {
            fontSize: '14px',
            lineHeight: '20px',
            color: theme.palette.text.secondary
          }
        : {
            lineHeight: '16px',
            fontSize: '12px',
            color: theme.palette.neutral.main
          })
    }
  }
}))
